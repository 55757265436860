import React from 'react';
import {LinearProgress} from "@material-ui/core";
import './skill.scss';
const skill = (props) => {
	return (
		<div className="skill mb-3">
			<label>{props.name}</label>
			<LinearProgress className="bg-warning p-1" variant="determinate" value={props.level} />
		</div>
	)
};

export default skill;
