import React, {Component, Fragment} from 'react';
import MouseTooltip from "react-sticky-mouse-tooltip";
import './mouseSpy.scss';
class MouseSpy extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMouseTooltipVisible: true,
			mouseActive: ''
		}
	}
	componentDidMount() {
		// window.addEventListener('wheel', this.handleScroll, true);
		// const dds = document.querySelectorAll('label, li');
		// dds.forEach(dd=> {
		// 	dd.addEventListener('mouseenter', () => {
		// 		dd.classList.add('zoom');
		// 		this.setState({
		// 			mouseActive: 'active'
		// 		});
		// 	});
		// 	dd.addEventListener('mouseleave', () => {
		// 		dd.classList.remove('zoom');
		// 		this.setState({
		// 			mouseActive: ''
		// 		});
		// 	});
		// });

		const anchors = document.querySelectorAll('a');

		anchors.forEach(anchor=> {
			anchor.addEventListener('mouseenter', () => {
				anchor.classList.add('mouseEnter');
				this.setState({
					mouseActive: 'active'
				});
			});
			anchor.addEventListener('mouseleave', () => {
				anchor.classList.remove('mouseEnter');
				this.setState({
					mouseActive: ''
				});
			});
		});
	}

	render() {
		return (
			<MouseTooltip
				offsetX={-16}
				offsetY={-16}
				className={`mouseSpy ${this.state.mouseActive}`}
			>
				<Fragment></Fragment>
			</MouseTooltip>
		);
	}
}

export default MouseSpy;
