import React from 'react';
import {Icon} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FadeInOut from "../components/FadeInOut/FadeInOut";

const About = (props) => {
	const active = props.active ? ' active' : '';

	return (
		<section className={`About${active} position-relative`}>
			<article>
				<header><h1>Resume</h1></header>
				<div className="articleContent container">
					<dl className="row">
						<dd className="col-md-2">I'm a:</dd>
						<dt className="col">
							<ul className="list-unstyled">
								<li>Passionate, adaptive, and committed problem solver. Looking for a <u>Senior Front End Engineer / UI Principal Engineer</u> role with a great team.</li>
								<li>Confident WordPress standard and ecommerce website developer</li>
								<li>Team player and tireless worker aiming for a unified goal of solving clients' problems</li>
							</ul>
						</dt>
					</dl>
					<dl className="row">
						<dd className="col-md-2">Main interests:</dd>
						<dt className="col">
							Angular, React, Graphing/Charting Software solutions, Responsive Design, OOP, and Progressive Web Applications
						</dt>
					</dl>
					<dl className="row">
						<dd className="col-md-2">I have:</dd>
						<dt className="col">
							<ul className="list-unstyled">
								<li>Great people skills with confident know-how about technologies and keen self-learner</li>
								<li>A wealth of UI/UX understanding</li>
								<li>The ability to mentor junior developers and demonstrate and explain technologies to stake holders.</li>
							</ul>
						</dt>
					</dl>

					<dl className="row">
						<dd className="col-md-2">Motto:</dd>
						<dt className="col">
							Overtime is always an option to get the job done!
						</dt>
					</dl>
				</div>
				<FadeInOut delay="2000" content={
					<div className="d-none d-lg-block text-center position-absolute w-100 fade-in-out" style={{bottom: '25px'}}>Scroll<br/><Icon component={KeyboardArrowDownIcon} fontSize="large"/></div>
				}/>
			</article>
		</section>
	);
}

export default About;
