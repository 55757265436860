import React, {Fragment} from 'react';
import Skill from "./skill";

const skillSection = (props) => {
	const skillsLength = props.skills.skills.length;
	const skills1 = props.skills.skills.map((skill, index) => {
		if(index < skillsLength/2) {
			return (
				<Skill name={skill.name} level={skill.level} key={index}/>
				)
		}
		return true;
	});
	const skills2 = props.skills.skills.map((skill, index) => {
		if (index >= skillsLength / 2) {
			return (
				<Skill name={skill.name} level={skill.level} key={index}/>)
		}
		return true;
	});

	return (
		<Fragment>
			<dt className="col-4 col-lg-2">{props.skills.section}:</dt>
			<dd className="border-bottom border-dotted border-secondary pb-1 col-8 col-lg-10">
				<div className="row">
					<div className="col-md-6">
						{skills1}
					</div>
					<div className="col-md-6">
						{skills2}
					</div>
				</div>
			</dd>
		</Fragment>
	);
};

export default skillSection;
