import React, {Component} from 'react';
import './animatedIcon.scss';

class AnimatedIcon extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anim: ''
		};

		this.handleClick = this.handleClick.bind(this);
	}

	handleClick (e) {
		e.preventDefault();
		const anim = this.state.anim === '' ? 'anim' : '';
		this.setState({anim});
	}

	render() {
		let anim;
		if(typeof this.props.anim !== "undefined"){
			anim = this.props.anim ? 'anim' : '';
		} else {
			anim = this.state.anim;
		}

		return (
			<div onClick={(e)=>this.handleClick(e)} className={`animated-icon ${this.props.icon} ${anim}`}>
				<div className="ani"></div>
			</div>
		);
	}
}

export default AnimatedIcon;