import React, {Component} from 'react';
import { Route, Switch} from "react-router-dom";
import ReactGA from 'react-ga';
import Home from './views/Home';
import TechSkills from "./views/TechSkills";
import Resume from "./views/Resume/Resume";
import Contact from "./views/ContactMe/Contact";
import './App.scss';
import NavMenu from "./components/menu/navMenu";
import MouseSpy from "./components/mouseSpy/mouseSpy";
import ScrollToTop from 'react-router-scroll-top'

class App extends Component {
    constructor() {
        super();

        this.state= {
            mode: false,
            dir: 'Right',
        };
        this.modeToggle = this.modeToggle.bind(this);
    }
    componentDidMount() {
        const animIcons = document.querySelectorAll('animated-icon');
        animIcons.forEach(animIcon=>{
            animIcon.addEventListener('click', () => {

            })
        });
        this.initializeReactGA();
    }

    initializeReactGA() {
        ReactGA.initialize('UA-164166331-1');
        ReactGA.pageview(window.location.pathname);
    }

    modeToggle() {
        return this.setState({
            'mode': !this.state.mode,
            'dir': !this.state.mode ? 'Left' : 'Right'
        })
    }
    render() {
        console.log('render!');
      const dt = new Date();
      const className = this.state.mode ? `App mode-left-hand` : 'App';
      return (
          <div className={className}>
              <ScrollToTop/>
             <MouseSpy/>
              <NavMenu modeToggle={this.modeToggle} dir={this.state.dir}/>
              <Switch>
                  <Route path="/skills" exact component={TechSkills} />
                  <Route path="/resume" exact component={Resume} />
                  <Route path="/contact" exact component={Contact} />
                  <Route path="/" exact component={Home} />
              </Switch>
              <footer className="p-2 footer small text-center">
                  <p>Website Build using React JS with Material Design</p>
                  <p>Code for this Website is Maintained on <a href="https://bitbucket.org/goldfarbdevelopment" rel="noopener noreferrer" target="_blank">BitBucket</a></p>
                  Copyright {dt.getFullYear()} <span className="h6">&copy;</span> By Yours Truly, <a href="https://gregorygoldfarb.com">Gregory B Goldfarb</a>
              </footer>
          </div>
      );
  }
}

export default App;
