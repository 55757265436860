import React, {Component} from 'react';
import Summary from "./Summary";
import {Paper} from "@material-ui/core";

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loadingDone: false
		};
		this.handleLoad = this.handleLoad.bind(this);
	}

	componentDidMount() {
		console.log('componentDidMount');
		window.addEventListener('load', this.handleLoad);
	}
	componentWillUnmount() {
		console.log('componentDidUnMount');
		window.removeEventListener('load', this.handleLoad);
	}

	handleLoad() {
		this.setState({loadingDone:true});
		localStorage.setItem('loaded', true);
	}

	render() {
		return (
			<main>
				<Paper
					name="Summary" elevation={3}>
					<Summary profile={this.state.loadingDone}/>
				</Paper>
			</main>
		);
	}
}

export default Home;
