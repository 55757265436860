import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import {Button, Card, CardContent} from "@material-ui/core";
import {Timer} from "@material-ui/icons";

export default function MyApps() {
	return (
			<section id="MyApps" className={`container`}>
				<article>
					<h1>My Apps</h1>
					<p>Applications that I create on my spare time:</p>

					<Card>
						<CardContent>
							<div className="row mb-5">
								<div className="col mb-3">
									<Button
										className="text-center px-5 py-3 w-100 mb-5"
										target="_blank"
										variant="outlined"
										href="https://gym-timer.com">
										<span className="text-secondary h6 d-inline d-sm-none"><Timer fontSize="large" className="text-dark"/><br/>GYM TIMER</span>
										<span className="text-secondary h5 d-none d-sm-inline"><Timer fontSize="large" className="text-dark"/><br/>GYM TIMER</span>
									</Button>
									<p>Built using MDB Angular Framework with the following features:</p>
									<ul className="text-left">
										<li>Customized Gym Timer</li>
										<li>SSO Login</li>
										<li>Customized Exercises with Individualizes Timers</li>
										<li>Edit and Delete Workouts</li>
										<li>Saving Daily Workouts</li>
										<li>Google API to provide YouTube Video Explanations for each workout.</li>
									</ul>
								</div>
								<div className="col mb-3">
									<iframe src="https://www.youtube.com/embed/cxpwP4sto00?si=_mwDK9aYjATFf0qu"
											width={300}
											height={600}
											title="YouTube video"
											allowFullScreen
									></iframe>
								</div>
							</div>
						</CardContent>
					</Card>
				</article>
			</section>
	);
}
