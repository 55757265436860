import React, {Component} from 'react';
import './FadeInOut.scss';
class FadeInOut extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content:this.props.content,
			fadeIn: ''
		};
		this.fadeIn = this.fadeIn.bind(this);
		this.fadeOut = this.fadeOut.bind(this);
	}


	componentDidMount() {
		this.fadeIn();
	}

	fadeIn() {
		setTimeout(()=>{
			this.setState({fadeIn: 'fade-in'}, this.fadeOut());
		}, this.props.delay);
	}

	fadeOut() {
		setTimeout(()=>{
			this.setState({fadeIn: 'fade-out'}, this.fadeIn());
		}, this.props.delay);
	}

	render() {
		return (
			<span className={this.state.fadeIn}>
				{this.props.content}
			</span>
		);
	}
}

export default FadeInOut;
