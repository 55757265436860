import React, {Fragment} from 'react';
import './summary.scss';
import {Button, Icon} from "@material-ui/core";
import ContactMe from "./ContactMe/ContactMe";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FadeInOut from "../components/FadeInOut/FadeInOut";
import {NavLink} from "react-router-dom";
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import HourglassFullTwoToneIcon from '@material-ui/icons/HourglassFullTwoTone';
import MyApps from "./MyApps/MyApps";
import DownloadResume from "../components/DownloadResume";

const Summary = (props) => {
	const profile = props.profile || localStorage.getItem('loaded') === "true" ? <img className="w-100" src="/assets/images/Gregory Goldfarb_1.jpg" alt="Gregory B Goldfarb" /> :
		(<HourglassFullTwoToneIcon style={{fontSize: "100px"}} className="h-75 Spinner"/>);
	return (
		<Fragment>
		<section id="Summary" className="Summary container-fluid">
			<article className="mb-3">
				<header><h1>Gregory Goldfarb</h1></header>
				<div className="articleContent container">
					<div className="row">
						<div className="mb-2 col-lg-6 text-center">
							{profile}
						</div>
						<div className="p-4 p-lg-0 col-lg-6">
							<p>Welcome, I am a <u>Software Engineer</u> and <u>Professional Musician</u> based out of Boston, MA. I take pride in building solutions and solving problems. My <em>goal</em> is to make the best creation I can for the end user.</p>

							<p>My background as an artist enables me to see the picture of what a true website or application should look like and then create the <em>"magic"</em> called the <u>User Experience</u>. Then I implement the functionality required to make the User Interface fun and interactive.</p>

							<p>Thank you very much for taking the time to see my website. If you are interested in my work, please <a href="#ContactMe">contact me.</a></p>

							<div className="text-right"><Button className="p-0" variant="outlined"><NavLink className="btn-link btn-lg text-decoration-none text-secondary" to="/resume">My Online Resume <AssignmentIndTwoToneIcon fontSize="large" className="text-dark"/> </NavLink></Button></div>
						</div>
					</div>
				</div>
			</article>
			<FadeInOut delay="2000" content={
				<div className="d-none d-lg-block text-center position-absolute w-100 fade-in-out" style={{bottom: '-20px'}}>Scroll<br/><Icon component={KeyboardArrowDownIcon} fontSize="large"/></div>
			}/>
		</section>
		<MyApps/>
		<DownloadResume/>
		<ContactMe/>

		</Fragment>
	);
}

export default Summary;
