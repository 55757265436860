import React, {Component} from 'react';
import TechSkills from "../TechSkills";
import WorkHistory from "../WorkHistory";
import {CircularProgress, Icon, Paper} from "@material-ui/core";
import { Link, Events, animateScroll as scroll } from 'react-scroll'

import {SvgIcon} from "@material-ui/core";
import FiberManualRecordTwoToneIcon from '@material-ui/icons/FiberManualRecordTwoTone';
import About from "../About";
import './Resume.scss';
import ContactMe from "../ContactMe/ContactMe";
import GetAppIcon from '@material-ui/icons/GetApp';
import DownloadResume from "../../components/DownloadResume";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FadeInOut from "../../components/FadeInOut/FadeInOut";

class Resume extends Component {
	constructor(props) {
		super(props);
		this.scrollToTop = this.scrollToTop.bind(this);
		this.state = {
			Current: 'About',
			Sections: {
				About: true,
				TechSkills: false,
				WorkHistory: false,
				ContactMe: false
			},
			scrollTop: 0,
			status: 0,
			disableScroll: false
		};
		this.strikes = 0;
		this.handleScroll = this.handleScroll.bind(this);
		this.getCurrentSection = this.getCurrentSection.bind(this);
		this.handleSpyClick = this.handleSpyClick.bind(this);
	}

	componentDidMount() {
		this.getCurrentSection();

		// const THIS = this;

		// Events.scrollEvent.register('begin', function () {
		// 	console.log("begin", arguments);
		// 	window.removeEventListener('wheel', THIS.handleScroll, true);
		// });
		//
		// Events.scrollEvent.register('end', function () {
		// 	console.log("end", arguments);
		// 	THIS.setState({
		// 		Sections:{
		// 			...THIS.state.Sections,
		// 			[THIS.state.Current]: false,
		// 			[arguments[0]]: true
		// 		},
		// 		Current: arguments[0]
		// 	});
		// 	window.addEventListener('wheel', THIS.handleScroll, true);
		// });
	}

	handleSetActive (to) {
		console.log("TO: " + to);
	}
	// getCurrentSection () {
	// 	const sections = Object.keys(this.state.Sections);
	// 	const current = sections.reduce((accumulator, section, currentIndex) => {
	// 		const location = document.getElementById(section).getBoundingClientRect().top < 0 ?
	// 			-(document.getElementById(section).getBoundingClientRect().top) :
	// 			document.getElementById(section).getBoundingClientRect().top;
	//
	// 		const prevLocation = document.getElementById(accumulator).getBoundingClientRect().top < 0 ?
	// 			-(document.getElementById(accumulator).getBoundingClientRect().top) :
	// 			document.getElementById(accumulator).getBoundingClientRect().top;
	//
	// 		return currentIndex === 0 || (location < prevLocation) ?
	// 			section : accumulator;
	// 	}, sections[0]);
	//
	// 	this.setState({
	// 		Current: current,
	// 		Sections: {
	// 			...this.state.Sections,
	// 			[this.state.Current]:false,
	// 			[current]: true,
	// 		}
	// 	});
	// }
	getCurrentSection () {
		const sections = Object.keys(this.state.Sections);
		const current = sections.reduce((accumulator, section, currentIndex) => {
			const location = document.getElementsByClassName(section)[0].getBoundingClientRect().top < 0 ?
				-(document.getElementsByClassName(section)[0].getBoundingClientRect().top) :
				document.getElementsByClassName(section)[0].getBoundingClientRect().top;

			const prevLocation = document.getElementById(accumulator).getBoundingClientRect().top < 0 ?
				-(document.getElementById(accumulator).getBoundingClientRect().top) :
				document.getElementById(accumulator).getBoundingClientRect().top;

			return currentIndex === 0 || (location < prevLocation) ?
				section : accumulator;
		}, sections[0]);

		this.setState({
			Current: current,
			Sections: {
				...this.state.Sections,
				[this.state.Current]:false,
				[current]: true,
			}
		});
	}

	componentWillUnmount() {
		Events.scrollEvent.remove('begin');
		Events.scrollEvent.remove('end');
	}

	handleScroll (e, current) {
		// console.log(e);
		this.strikes++;
		console.log('STRIKES' + this.strikes);
		if(this.strikes < 10) {
			if(!this.state.disableScroll) {
				this.disableScroll();
			}
			this.strikes++;
			return;
		} else {
			this.setState({disableScroll: false});
			this.enableScroll();
		}
		// console.log(e);
		const sections = Object.keys(this.state.Sections);
		// console.log('CURRENT: ' + current);
		// console.log('INDEX: ' + sections.indexOf(current));
		const upDown = e.deltaY > this.state.scrollTop ? 1 : -1;
		const upOrDown = upDown === 1 ? 'DOWN' : 'UP';
		// console.log('upDown: '+upDown);
		// console.log(upOrDown);
		const next = (sections.indexOf(current)) + upDown;
		// console.log('Next: ' + sections.indexOf(sections[next]));
		if(sections[next] && document.getElementById(sections[next]) &&
			((upOrDown === 'UP' && sections.indexOf(current) !== 0) ||
			(upOrDown === 'DOWN' && sections.indexOf(sections[next]) !== -1))) {
			console.log('Next Section ID:' + sections[next]);
			console.log(document.getElementById(sections[next]));
			const currentSection = document.getElementsByClassName(current)[0];
			console.log(`WindowScroll ${window.scrollY}`);
			if (
				currentSection &&
				(
				(
					upOrDown === 'DOWN' &&
					((currentSection.scrollHeight - window.innerHeight) <= window.scrollY + 100)
				) ||
				(
					upOrDown === 'UP' && window.scrollY <= 100
				)
				)
			){
			// console.log('STRIKES ' + this.state.status);
				this.setState({status: this.state.status+4});
				const destination = sections[next].toString();
				// console.log('Destination: ' + destination);
				if(this.state.status >= 100) {

					this.setState(
						{
							Current: destination,
							Sections: {
								...this.state.Sections,
								[this.state.Current]:false,
								[destination]: true,
							},
							status:0
						}
					, this.scrollToTop());
				}
			}
		}
		else {this.setState({status:0})}
	}

	scrollToTop() {

		scroll.scrollToTop({
			duration: 500,
			delay: 250,
			smooth: 'easeInOutQuart'
		});
	}

handleSpyClick(e) {
	e.preventDefault();
	this.setState({
		Current: [e.currentTarget.dataset.target],
		Sections: {
			...this.state.Sections,
			[this.state.Current]:false,
			[e.currentTarget.dataset.target]: true
		}
	}, this.scrollToTop());
	this.scrollToTop();
}

	render() {
		return (
			<div className="Resume">
				<nav className="spyNav">
					<ul className="spyNav-ul list-unstyled">
						<li>
							<Link
								// data-target="About"
								// onMouseUp={(e)=>{this.handleSpyClick(e);}}
								spy={true}
								smooth={true}
								hashSpy={true}
								duration={500}
								activeClass="active"
								className="linkTo__About"
								to="About" href={"true"} >
								<SvgIcon component={FiberManualRecordTwoToneIcon} /><span>About</span>
							</Link>
						</li>
						<li>
							<Link
								// data-target="TechSkills"
								// onMouseUp={(e)=>{this.handleSpyClick(e);}}
								spy={true}
								hashSpy={true}
								smooth={true}
								duration={500}
								activeClass="active"
								className="linkTo__TechSkills" to="TechSkills" href={"true"}>
								<SvgIcon component={FiberManualRecordTwoToneIcon} />
								<span>Skills</span>
							</Link>
						</li>
						<li>
							<Link
								// data-target="WorkHistory"
								// onMouseUp={(e)=>{this.handleSpyClick(e);}}
								spy={true}
								duration={500}
								smooth={true}
								activeClass="active"
								className="linkTo__WorkHistory" href={"true"} to="WorkHistory">
								<SvgIcon component={FiberManualRecordTwoToneIcon} />
								<span>History</span>
							</Link>
						</li>
						<li>
							<Link
								// data-target="WorkHistory"
								// onMouseUp={(e)=>{this.handleSpyClick(e);}}
								spy={true}
								duration={500}
								smooth={true}
								activeClass="active"
								className="linkTo__DownloadResume" href={"true"} to="DownloadResume">
								<SvgIcon component={FiberManualRecordTwoToneIcon} />
								<span><GetAppIcon fontSize="large" className="text-dark"/>Resume</span>
							</Link>
						</li>
						<li>
							<Link
								// data-target="WorkHistory"
								// onMouseUp={(e)=>{this.handleSpyClick(e);}}
								spy={true}
								duration={500}
								smooth={true}
								activeClass="active" href={"true"}
								className="linkTo__ContactMe" to="ContactMe">
								<SvgIcon component={FiberManualRecordTwoToneIcon} />
								<span>Contact</span>
							</Link>
						</li>
					</ul>
				</nav>
				<main>
					<Paper
						className={this.state.Sections.About ? 'active' : ''}
						// onTouchMoveCapture={(e)=>{this.handleScroll(e, 'About')}}
						// onTouchEnd={(e)=>{this.handleScroll(e, 'About')}}
						// onWheel={(e)=>{this.handleScroll(e, 'About')}}
						id="About" name="About" elevation={3}>
						<About />
					</Paper>
					<Paper
						className={this.state.Sections.TechSkills ? 'active' : ''}
						// onTouchMoveCapture={(e)=>{this.handleScroll(e, 'TechSkills')}}
						// onWheel={(e)=>{this.handleScroll(e,'TechSkills')}}
						id="TechSkills" name="TechSkills" elevation={3}>
						<TechSkills />
						<FadeInOut delay="2000" content={
							<div className="d-none d-lg-block text-center w-100 fade-in-out" style={{bottom: '25px'}}>Scroll<br/><Icon component={KeyboardArrowDownIcon} fontSize="large"/></div>
						}/>
					</Paper>
					<Paper
						className={this.state.Sections.WorkHistory ? 'active' : ''}
						// onTouchMoveCapture={(e)=>{this.handleScroll(e, 'WorkHistory')}}
						// onWheel={(e)=>{this.handleScroll(e,'WorkHistory')}}
						id="WorkHistory" name="WorkHistory" elevation={3}>
						<WorkHistory />
					</Paper>
					<Paper
						className={this.state.Sections.WorkHistory ? 'active' : ''}
						// onTouchMoveCapture={(e)=>{this.handleScroll(e, 'WorkHistory')}}
						// onWheel={(e)=>{this.handleScroll(e,'WorkHistory')}}
						id="DownloadResume" name="DownloadResume" elevation={3}>
						<DownloadResume/>
					</Paper>
					<Paper
						className={this.state.Sections.ContactMe ? 'active' : ''}
						// onTouchMoveCapture={(e)=>{this.handleScroll(e, 'WorkHistory')}}
						// onWheel={(e)=>{this.handleScroll(e,'WorkHistory')}}
						id="ContactMe" name="ContactMe" elevation={3}>
						<ContactMe />
					</Paper>
				</main>
				<div className="fixed-bottom">
					<CircularProgress variant="static" value={this.state.status} />
				</div>
			</div>
		);
	}
}

export default Resume;
