import React from 'react';
import {Icon, Paper} from "@material-ui/core";
import ContactMe from "./ContactMe";
import './Contact.scss';
import DownloadResume from "../../components/DownloadResume";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FadeInOut from "../../components/FadeInOut/FadeInOut";

const Contact = (props) => {
	return (<>
			<Paper id="Contact">
				<ContactMe/>
				<div className="d-lg-none text-center">
					<FadeInOut
						delay="2000"
						content={(
							<span>
								Scroll<br/>
								<Icon
									component={KeyboardArrowDownIcon}
									fontSize="large"
								/>
							</span>)
				}/>
				</div>
			</Paper>
			<Paper id="Contact">
				<DownloadResume/>
				<div className="d-lg-none text-center">
					<FadeInOut
						delay="2000"
						content={(
							<span>
								Scroll<br/>
								<Icon
									component={KeyboardArrowDownIcon}
									fontSize="large"
								/>
							</span>)
				}/>
				</div>
			</Paper>
		</>
	);
};

export default Contact;
