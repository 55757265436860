import React from 'react';
import '../workHistory.scss';
import 'react-vertical-timeline-component/style.min.css';
import {Button} from "@material-ui/core";
import ContactMailTwoToneIcon from "@material-ui/icons/ContactMailTwoTone";
import ContactPhoneTwoToneIcon from "@material-ui/icons/ContactPhoneTwoTone";
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import "./ContactMe.scss";
import {GitHub} from "@material-ui/icons";
import {IoLogoBitbucket} from "react-icons/all";

const ContactMe = (props) => {
	const active = props.active ? ' active' : '';
	return (
			<section id="ContactMe" className={`ContactMe${active} container`}>
				<article>
					<h1>Contact</h1>
					<div className="row text-center mb-5">
						<div className="col-4 mb-3">
							<Button
								className="px-5 py-3 w-100"
								variant="outlined"
								target="_blank"
								href="mailto:goldfarbdev@gmail.com">
								<span className="text-secondary h6 d-inline d-sm-none"><ContactMailTwoToneIcon fontSize="large" className="text-dark"/><br/>EMAIL</span>
								<span className="text-secondary h5 d-none d-sm-inline"><ContactMailTwoToneIcon fontSize="large" className="text-dark"/><br/>EMAIL</span>
							</Button>
						</div>
						<div className="col-4 mb-3">
							<Button
								className="px-5 py-3 w-100"
								target="_blank"
								variant="outlined"
								href="tel:7813301734">
								<span className="text-secondary h6 d-inline d-sm-none"><ContactPhoneTwoToneIcon fontSize="large" className="text-dark"/><br/>CALL</span>
								<span className="text-secondary h5 d-none d-sm-inline"><ContactPhoneTwoToneIcon fontSize="large" className="text-dark"/><br/>CALL</span>
							</Button>
						</div>
						<div className="col-4 mb-3">
							<Button
								className="px-5 py-3 w-100"
								target="_blank"
								variant="outlined"
								href="https://www.linkedin.com/in/goldfarbdev/">
								<span className="text-secondary h6 d-inline d-sm-none"><LinkedInIcon fontSize="large"  className="text-dark"/><br/>LINKEDIN</span>
								<span className="text-secondary h5 d-none d-sm-inline"><LinkedInIcon fontSize="large"  className="text-dark"/><br/>LINKEDIN</span>
							</Button>
						</div>
						<div className="col-12">
							<h2 className="my-3">PROJECTS</h2>
							<div className="row">

								<div className="col-4 offset-2">
									<Button
										className="px-5 py-3 w-100"
										target="_blank"
										variant="outlined"
										href="https://github.com/goldfarbdev">
										<span className="text-secondary h6 d-inline d-sm-none"><GitHub fontSize="large"  className="text-dark"/><br/>GitHub</span>
										<span className="text-secondary h5 d-none d-sm-inline"><GitHub fontSize="large"  className="text-dark"/><br/>GitHub</span>
									</Button>
								</div>
								<div className="col-4">
									<Button
										className="px-5 py-3 w-100"
										target="_blank"
										variant="outlined"
										href="https://bitbucket.org/goldfarbdevelopment">
										<span className="text-secondary h6 d-inline d-sm-none"><IoLogoBitbucket fontSize="large"  className="text-dark" style={{fontSize: "2.25em"}}/><br/>BitBucket</span>
										<span className="text-secondary h5 d-none d-sm-inline"><IoLogoBitbucket fontSize="large"  className="text-dark" style={{fontSize: "1.75em"}}/><br/>BitBucket</span>
									</Button>
								</div>
							</div>
						</div>
					</div>
				</article>
			</section>
	);
};

export default ContactMe;
