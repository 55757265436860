import React, {Component} from 'react';
import SkillSection from '../components/skillSection';
class TechSkills extends Component {

	render() {
		const skillSections = [
			{
				section: "Industry Knowledge",
				skills: [
					{name: "UI/UX", level: 90},
					{name: "Design to Code", level: 100},
					{name: "Mobile Applications", level: 75},
					{name: "E-commerce", level: 75},
					{name: "Usability", level: 85},
					{name: "Web Services", level: 70},
					{name: "Information Architecture", level: 60},
					{name: "Front-end Development", level: 90},
					{name: "Agile Methodologies", level: 70}
				]
			},
			{
				section: "Front End",
				skills: [
					{name: "CSS", level: 95},
					{name: "HTML", level: 95},
					{name: "JavaScript", level: 85},
					{name: "HTML5", level: 95},
					{name: "MySQL", level: 30},
					{name: "Flash", level: 75},
					{name: "grunt", level: 75},
					{name: "React.js", level: 90},
					{name: "Redux.js", level: 75},
					{name: "AJAX", level: 100},
					{name: "Angular", level: 100},
					{name: "RxJS / Observables", level: 90},
					{name: "Google Charts", level: 100},
					{name: "Data Tables", level: 100},
					{name: "charts.js", level: 100},
					{name: "jQuery", level: 100},
					{name: "LESS", level: 100},
					{name: "Jest", level: 100},
					{name: "SASS", level: 100},
					{name: "JS Lint", level: 100},
					{name: "Cross-Browser Compatibility", level: 100},
					{name: "Adobe Creative Suite", level: 70},
				]
			},
			{
				section: "Back End",
				skills: [
					{name: "gRPC",  level: 50},
					{name: "HTTP/2",  level: 50},
					{name: "PHP", level: 75},
					{name: "ActionScript", level: 60},
					{name: "phpMyAdmin", level: 70},
					{name: "LAMP", level: 80},
					{name: "Java", level: 20},
					{name: "JSON", level: 100},
					{name: "CMS", level: 86},
					{name: "Eclipse", level: 50},
					{name: "MVC Framework", level: 95},
					{name: "Symfony", level: 85},
					{name: "MySQL", level: 50},
					{name: "WordPress", level: 90},
					{name: "Demandware", level: 50},
					{name: "Woo Commerce", level: 80},
					{name: "iApps", level: 70},
					{name: "ASP.net", level: 60},
					{name: "Twig", level: 60}
				]
			},
			{
				section: "Tools",
				skills: [
					{name: "ActionScript", level: 60},
					{name: "phpMyAdmin", level: 70},
					{name: "LAMP", level: 80},
					{name: "JSON", level: 100},
					{name: "CMS", level: 86},
					{name: "Eclipse", level: 70},
					{name: "Bower", level: 60},
					{name: "npm", level: 80},
					{name: "Composer", level: 70},
					{name: "PHP Storm / IntelliJ IDEA", level: 100},
					{name: "Adobe Creative Suite/Cloud", level: 80},
					{name: "Fireworks", level: 60},
					{name: "Flash", level: 70},
					{name: "InDesign", level: 50},
					{name: "Illustrator", level: 50},
					{name: "Notepad++", level: 100},
					{name: "JIRA", level: 100},
					{name: "Microsoft Project Server", level: 65},
					{name: "Sublime Text", level: 80},
					{name: "Creative Manager", level: 70},
					{name: "Smart SVN", level: 70},
					{name: "XAMPP/WAMP", level: 75},
					{name: "Sequel Pro", level: 70},
					{name: "Microsoft Visual Studio", level: 70},
					{name: "BitBucket", level: 85},
					{name: "Source Tree", level: 85},
					{name: "Docker", level: 70},
					{name: "Accurev", level: 70},
					{name: "Rally", level: 90},
					{name: "Grunt", level: 70}
				]
			}
		];


		function compare(a, b) {
			// Use toUpperCase() to ignore character casing
			const nameA = a.name.toUpperCase();
			const nameB = b.name.toUpperCase();

			let comparison = 0;
			if (nameA > nameB) {
				comparison = 1;
			} else if (nameA < nameB) {
				comparison = -1;
			}
			return comparison;
		}

		skillSections.map(skillSection=>{
			return skillSection.skills.sort(compare);
		});

		let buildSkills = skillSections.map((section, i) => {
			return <SkillSection skills={section} key={i}/>
		});

		const active = this.props.active ? ' active' : '';
		return (
			<section className={`TechSkills${active}`}>
				<article className="container">
					<h1>SKILLS AND EXPERIENCE</h1>
					<dl className="row">
						<dt className="col-4 col-lg-2">Mindset:</dt>
						<dd className="col-8 col-lg-10">Object Oriented Programming, detail oriented, precision,
							innovative
						</dd>
						{buildSkills}
					</dl>
				</article>
			</section>
		);
	}
}

export default TechSkills;
