import React, {Component} from 'react';
import { NavLink } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeIcon from '@material-ui/icons/Home';
import AnimatedIcon from "../icons/animatedIcon";
import PermPhoneMsgIcon from '@material-ui/icons/PermPhoneMsg';
import SmartphoneTwoToneIcon from '@material-ui/icons/SmartphoneTwoTone';
import ThumbUpAltTwoToneIcon from '@material-ui/icons/ThumbUpAltTwoTone';
import './NavMenu.scss';

class NavMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			top: false,
			left: false,
			bottom: false,
			right: false
		};

		this.toggleDrawer = this.toggleDrawer.bind(this);
	}

	toggleDrawer (event, side) {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		this.setState({ ...this.state, [side]: !this.state[side] });
	};
	render() {
		const menuItems = [
			{
				name:'Home',
				icon: <HomeIcon />,
				path: '/'
			},
			{
				name:'Resume',
				icon: <DescriptionIcon />,
				path: '/resume'
			},
			{
				name: "Contact and CV Download",
				icon: <PermPhoneMsgIcon/>,
				path:'/contact'
			}
			// {
			// 	name: 'Portfolio',
			// 	icon: <CodeIcon />,
			// 	path: '/portfolio'
			// },
			// {
			// 	name: 'Contact',
			// 	icon: <ChatBubbleIcon />,
			// 	path: '/contact'
			// }
			];
		const dir = this.props.dir === 'Left' ? 'Right' : 'Left';
		const fullList = side => (
			<div
				className={`fullList fullList-${this.props.dir}`}
				role="presentation"
				onClick={(e)=>{this.toggleDrawer(e, side, false)}}
				onKeyDown={(e)=>{this.toggleDrawer(e, side, false)}}
			>
				<List>
					{menuItems.map((menuItem, index) => (
						<ListItem className="d-block" button key={menuItem.name}
						          onClick={(e)=>{this.toggleDrawer(e, side, false)}}
						          onKeyDown={(e)=>{this.toggleDrawer(e, side, false)}}>
							<NavLink
								className="d-block" to={menuItem.path}
							>
								<ListItemIcon>{menuItem.icon}</ListItemIcon>
								<ListItemText className="d-inline-block" secondary={menuItem.name} />
							</NavLink>
						</ListItem>
					))}
					<ListItem className="d-block" button onClick={this.props.modeToggle}>
						<ListItemIcon><ThumbUpAltTwoToneIcon/><SmartphoneTwoToneIcon/></ListItemIcon>
						<ListItemText className="d-inline-block" secondary={`Try ${dir} Handed Mode`} />
					</ListItem>
				</List>
			</div>
		);
		return (
			<div className="NavMenu position-fixed bg-white">
				<NavLink className="logo" to="/">G<span className="initials">B</span><span className="initials">G</span><span className="train">regory Benjamin Goldfarb</span></NavLink>
				<Button className="buttonMenu" onClick={(e)=>{this.toggleDrawer(e, 'top', true)}}><AnimatedIcon anim={this.state.top} icon="menu-cross-s" /></Button>
				<Drawer anchor="top" open={this.state.top} onClose={(e)=>{this.toggleDrawer(e,'top', false)}}>
					{fullList('top')}
				</Drawer>
			</div>
		);
	}
}

export default NavMenu;
